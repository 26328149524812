import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FeSalesdItemsService {
    getApiUrl() {
        return API_URL
    }
    getAllFeSalesdItems(){
        return axios.get(API_URL + `getAllFeSalesdItems`, { headers: authHeader() });
    }
    getAllFeSalesdItemsContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFeSalesdItems`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFeSalesdItemsContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFeSalesdItemsById(id){
        return axios.get(API_URL + `getFeSalesdItemsById/${id}`, { headers: authHeader() });
    }
    getAllFeSalesdItemsByParent(id){
        return axios.get(API_URL + `getAllFeSalesdItemsByParent/${id}`, { headers: authHeader() });
    }
    getAllFeSalesdItemsByParentIds(ids){
        return axios.get(API_URL + `getAllFeSalesdItemsByParentIds/${ids}`, { headers: authHeader() });
    }

    updateFeSalesdItems(item){
        return axios.put(API_URL + `updateFeSalesdItems/${item.id}`, item, {headers: authHeader()})
    }
    updateFeSalesdItemsCancelQtyByParent(feSaleshBean){
        return axios.put(API_URL + `updateFeSalesdItemsCancelQtyByParent/${feSaleshBean.id}`, feSaleshBean,{headers: authHeader()})
    }

    createFeSalesdItems(item){
        return axios.post(API_URL + `createFeSalesdItems`, item, {headers: authHeader()})
    }
    deleteFeSalesdItems(id){
        return axios.delete(API_URL + `deleteFeSalesdItems/${id}`, {
            headers: authHeader()
        });
    }

    deleteFeSalesdItemsAvatarByParent(fkegiatanBean){
        return axios.delete(API_URL + `deleteFeSalesdItemsAvatarByParent/${fkegiatanBean}`, {
            headers: authHeader()
        });
    }

    createFeSalesdItemsAvatar(item){
        return axios.post(API_URL + `createFeSalesdItemsAvatar`, item, {headers: authHeader()})
    }

    deleteAllFeSalesdItems(itemIds){

        return axios.delete(API_URL + `deleteAllFeSalesdItems`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FeSalesdItemsService()