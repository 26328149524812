import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FeSaleshService {
    getApiUrl() {
        return API_URL
    }

    getAllFeSalesh(){
        return axios.get(API_URL + `getAllFeSalesh`, { headers: authHeader() });
    }

    getAllFeSaleshContaining(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `getAllFeSaleshContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getAllFeSaleshContainingLight(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `getAllFeSaleshContainingLight?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }


    getAllFeSaleshByIds(itemIds){
        return axios.post(API_URL + `getAllFeSaleshByIds`, itemIds, { headers: authHeader() });
    }
    getAllFeSalesDetailByIds(itemIds){
        return axios.post(API_URL + `getAllFeSalesDetailByIds`, itemIds, { headers: authHeader() });
    }


    getAllFeSaleshContainingExt(item){
        // console.log(JSON.stringify(item))
        return axios.post(API_URL + `getAllFeSaleshContainingExt`, item, { headers: authHeader() });
    }
    getAllFeSaleshContainingExtLight(item){
        console.log('getAllFeSaleshContainingExtLight')
        return axios.post(API_URL + `getAllFeSaleshContainingExtLight`, item, { headers: authHeader() });
    }

    getAllFeSaleshContainingAndRemarkInExt(item){
        // console.log(`${JSON.stringify(item)}`)
        return axios.post(API_URL + `getAllFeSaleshContainingAndRemarkInExt`, item, { headers: authHeader() });
    }

    getAllFeSaleshContainingDesSimple(item){
        // console.log(JSON.stringify(item))
        return axios.post(API_URL + `getAllFeSaleshContainingDesSimple`, item, { headers: authHeader() });
    }


    getAllFeSaleshContainingExtSummary(item){
        return axios.post(API_URL + `getAllFeSaleshContainingExtSummary`, item, { headers: authHeader() });
    }

    postReportJasperPrintPos(item){
        return axios.post(API_URL + `reports/jasper/print_pos`, item, { headers: authHeader(), responseType:'blob' });
    }

    postReportJasperResiJnt(item){
        return axios.post(API_URL + `reports/jasper/resi_jnt`, item, { headers: authHeader(), responseType:'blob' });
    }
    postReportJasperResiJne(item){
        return axios.post(API_URL + `reports/jasper/resi_jne`, item, { headers: authHeader(), responseType:'blob' });
    }
    postReportJasperResiIdExpress(item){
        return axios.post(API_URL + `reports/jasper/resi_id_express`, item, { headers: authHeader(), responseType:'blob' });
    }
    postReportJasperResiSiCepat(item){
        return axios.post(API_URL + `reports/jasper/resi_si_cepat`, item, { headers: authHeader(), responseType:'blob' });
    }


    getFeSaleshById(id){
        return axios.get(API_URL + `getFeSaleshById/${id}`, { headers: authHeader() });
    }
    updateFeSalesh(item){
        // if(item.destName.includes("Angga")){
        //     console.log(JSON.stringify(item))
        // }
        return axios.put(API_URL + `updateFeSalesh/${item.id}`, item, {headers: authHeader()})
    }
    updateFeSaleshDeliveryStatus(item){
        return axios.put(API_URL + `updateFeSaleshDeliveryStatus/${item.id}`, item, {headers: authHeader()})
    }
    updateFeSaleshDeliveryStatusWithTime(item){
        return axios.put(API_URL + `updateFeSaleshDeliveryStatusWithTime/${item.id}`, item, {headers: authHeader()})
    }
    updateFeSaleshCreateInvoiceNo(item){
        return axios.put(API_URL + `updateFeSaleshCreateInvoiceNo/${item.id}`, item, {headers: authHeader()})
    }
    updateFeSaleshProcess(item, fwarehouseBean, fexpedisiBean){
        return axios.put(API_URL + `updateFeSaleshProcess/${item.id}/${fwarehouseBean}/${fexpedisiBean}`, item, {headers: authHeader()})
    }
    updateFeSaleshCancelAwb(item, fwarehouseBean, fexpedisiBean){
        return axios.put(API_URL + `updateFeSaleshCancelAwb/${item.id}/${fwarehouseBean}/${fexpedisiBean}`, item, {headers: authHeader()})
    }

    createFeSalesh(item){
        // console.log('Call Create New feSalesh')
        return axios.post(API_URL + `createFeSalesh`, item, {headers: authHeader()})
    }
    deleteFeSalesh(id){
        return axios.delete(API_URL + `deleteFeSalesh/${id}`, {
            headers: authHeader()
        });
    }
    deleteFeSaleshWithChildren(id){
        return axios.delete(API_URL + `deleteFeSaleshWithChildren/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFeSalesh(itemIds){
        return axios.delete(API_URL + `deleteAllFeSalesh`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FeSaleshService()
