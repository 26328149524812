import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class ApiKinoService {
    getSyncKinoT002(){
        return axios.get(API_URL + `ext/getSyncKinoT002`, { headers: authHeader() });
    }

    postKinoT003(filter){
        return axios.post(API_URL + `ext/postKinoT003`, filter,{ headers: authHeader() });
    }

}
export default new ApiKinoService()
