export default class FeSalesh {
  constructor(
      id,
      orderno,
      orderDate,
      invoiceno,
      invoiceDate,
      dueDate,
      deliveryNumber,
      sortingCode,
      deliveryDate,
      fdivisionBean,
      fsalesmanBean,
      fcustomerBean,
      fwarehouseBean,
      fstoreBean,
      validOrder,
      priority,
      noPromotionRules,
      taxNumber,
      taxDate,
      statusPengiriman,
      sjPengirimanNo,
      sjPengirimanDate,
      driverBean,
      nopol,
      sjPenagihanNo,
      sjPenagihanDate,
      collectorBean,
      top,
      amountRp,
      amountPpnRp,
      fuangMuka_SOBean,
      disc1,
      disc2,
      discPlus,
      amountAfterDiscPlusRp,
      ppnRp,
      amountAfterDiscPlusRpAfterPpn,
      amountPayRp,
      endOfDay,
      openLockInputPriceAndDiscount,
      statusRequestDiscount,
      statusRequestPlafon,
      notes,
      tunaiKredit,
      fpayMethodBean,
      tipeFaktur,
      salesType,
      printCounter,
      usedSO,
      amountKasirBayar,
      accAccountArKbBean,
      accAccountFeSaleshCredit,
      fexpedisiBean,
      destCode,
      fsubAreaBean,
      ongkirRp,
      biayaRp,
      codRp,
      noBiaya,
      destName,
      destAddress1,
      destDistrict1,
      destCity1,
      destState1,
      destPhone,
      destCountryCode,
      destZipCode,
      remark,
      remarkExt1,
      temp,
      listFeSalesdItems,
      sourceId,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.orderno = orderno;
    this.orderDate = orderDate;
    this.invoiceno = invoiceno;
    this.invoiceDate = invoiceDate;
    this.dueDate = dueDate;
    this.deliveryNumber = deliveryNumber;
    this.sortingCode = sortingCode;
    this.deliveryDate = deliveryDate;
    this.fdivisionBean = fdivisionBean;
    this.fsalesmanBean = fsalesmanBean;
    this.fcustomerBean = fcustomerBean;
    this.fwarehouseBean = fwarehouseBean;
    this.fstoreBean = fstoreBean;
    this.validOrder = validOrder;
    this.priority = priority;
    this.noPromotionRules = noPromotionRules;
    this.taxNumber = taxNumber;
    this.taxDate = taxDate;
    this.statusPengiriman = statusPengiriman;
    this.sjPengirimanNo = sjPengirimanNo;
    this.sjPengirimanDate = sjPengirimanDate;
    this.driverBean = driverBean;
    this.nopol = nopol;
    this.sjPenagihanNo = sjPenagihanNo;
    this.sjPenagihanDate = sjPenagihanDate;
    this.collectorBean = collectorBean;
    this.top = top;
    this.amountRp = amountRp;
    this.amountPpnRp = amountPpnRp;
    this.fuangMuka_SOBean = fuangMuka_SOBean;
    this.disc1 = disc1;
    this.disc2 = disc2;
    this.discPlus = discPlus;
    this.amountAfterDiscPlusRp = amountAfterDiscPlusRp;
    this.ppnRp = ppnRp;
    this.amountAfterDiscPlusRpAfterPpn = amountAfterDiscPlusRpAfterPpn;
    this.amountPayRp = amountPayRp;
    this.endOfDay = endOfDay;
    this.openLockInputPriceAndDiscount = openLockInputPriceAndDiscount;
    this.statusRequestDiscount = statusRequestDiscount;
    this.statusRequestPlafon = statusRequestPlafon;
    this.notes = notes;
    this.tunaiKredit = tunaiKredit;
    this.fpayMethodBean = fpayMethodBean;
    this.tipeFaktur = tipeFaktur;
    this.salesType = salesType;
    this.printCounter = printCounter;
    this.usedSO = usedSO;
    this.amountKasirBayar = amountKasirBayar;
    this.accAccountArKbBean = accAccountArKbBean;
    this.accAccountFeSaleshCredit = accAccountFeSaleshCredit;
    this.fexpedisiBean = fexpedisiBean;
    this.destCode = destCode;
    this.fsubAreaBean = fsubAreaBean;
    this.ongkirRp = ongkirRp;
    this.biayaRp = biayaRp;
    this.codRp = codRp;
    this.noBiaya = noBiaya;
    this.destName = destName;
    this.destAddress1 = destAddress1;
    this.destDistrict1 = destDistrict1;
    this.destCity1 = destCity1;
    this.destState1 = destState1;
    this.destPhone = destPhone;
    this.destCountryCode = destCountryCode;
    this.destZipCode = destZipCode;
    this.remark = remark;
    this.remarkExt1 = remarkExt1;
    this.temp = temp;
    this.listFeSalesdItems = listFeSalesdItems;
    this.sourceId = sourceId;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}
